<template>
  <div class="admin-bulk-command-devices">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Bulk Command Devices</h2></b-card-header>
          <b-card-body>
            <div class="device-select-toolbar">
              <div class="device-select-heading">Selected Devices</div>
              <i class="icon-button" :class="$config.icons.general.add" @click="$bvModal.show('modal-select-devices')"></i>
              <i class="icon-button-warning" :class="$config.icons.general.remove" @click="clickClearSelection"></i>
            </div>
<!--            <div class="device-select-container">-->
<!--              <div class="device-select-item" v-for="device of selectedDevices" :key="device.id">-->
<!--                {{device.device_id}} - {{device.name}}-->
<!--                <i class="fa fa-times row-icon-button-danger" @click="clickRemoveDevice(device)"></i>-->
<!--              </div>-->
<!--            </div>-->
            <div class="command-container">
             <admin-device-configurator :devices="selectedDevices"></admin-device-configurator>
            </div>
          </b-card-body>
      </b-card>
    </div>

    <!--    Device Select Modal Template-->
    <b-modal id="modal-select-devices" centered class="modal-content" size="xl" hide-footer title="Select Devices">
      <admin-device-list :show-fields="deviceFields" @change="changeDeviceSelect"></admin-device-list>
      <b-button @click="clickAddDevices">Add Devices</b-button>
    </b-modal>
  </div>
</template>

<script>
// import * as ErrorHelper from '../../components/helpers/ErrorHelper'
// import * as DataProvider from '../../components/helpers/DataProvider'

import AdminDeviceList from '../../components/admin/AdminDeviceList'
import AdminDeviceConfigurator from '../../components/admin/AdminDeviceConfigurator'
export default {
  name: 'admin-bulk-command-devices',
  components: {
    AdminDeviceConfigurator,
    AdminDeviceList
  },
  data: function () {
    return {
      selectedDevices: [],
      tempSelect: null,
      deviceFields: ['selected', 'imei', 'device_code', 'name', 'device_phone', 'location.datetime']
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    changeDeviceSelect: function (selection) {
      this.tempSelect = selection
    },
    clickAddDevices: function () {
      // TODO - Dedupe the device selection when we have real data to work with
      this.selectedDevices = this.selectedDevices.concat(this.tempSelect)
      this.$bvModal.hide('modal-select-devices')
      console.log(this.selectedDevices)
    },
    clickRemoveDevice: function (device) {
      let idx = this.selectedDevices.indexOf(device)
      this.selectedDevices.splice(idx, 1)
    },
    clickClearSelection: function () {
      this.selectedDevices = []
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-bulk-command-devices {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
  }

  .device-select-toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 2rem;
  }

  .device-select-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: $theme-color-background-1;
    padding: 5px;
    width: calc(100% - 20px);
    min-height: 2rem;
    max-height: 15vh;
    overflow-y: scroll;
    margin: 0px 10px 0px 10px;
  }

  .device-select-heading {
    text-align: left;
    color: $theme-color-primary-3;
    align-self: center;
  }

  .device-select-item {
    padding: 3px;
    width: 20%;
    height: 2rem;
    line-height: 2rem;
    background: $theme-color-primary-6;
    border-radius: 5px;
    margin: 5px 10px;
    min-width: fit-content;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

  .command-container {
    height: 50vh;
    position: relative;
  }

</style>
