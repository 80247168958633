var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-bulk-command-devices" },
    [
      _c("div", { staticClass: "toolbar justify-content-between" }, [
        _c("div", [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "icon-button",
            class: _vm.$config.icons.general.back,
            attrs: { title: "Back to Admin" },
            on: { click: _vm.clickBack }
          })
        ])
      ]),
      _c(
        "div",
        { staticClass: "list-containter" },
        [
          _c(
            "b-card",
            [
              _c("b-card-header", [_c("h2", [_vm._v("Bulk Command Devices")])]),
              _c("b-card-body", [
                _c("div", { staticClass: "device-select-toolbar" }, [
                  _c("div", { staticClass: "device-select-heading" }, [
                    _vm._v("Selected Devices")
                  ]),
                  _c("i", {
                    staticClass: "icon-button",
                    class: _vm.$config.icons.general.add,
                    on: {
                      click: function($event) {
                        return _vm.$bvModal.show("modal-select-devices")
                      }
                    }
                  }),
                  _c("i", {
                    staticClass: "icon-button-warning",
                    class: _vm.$config.icons.general.remove,
                    on: { click: _vm.clickClearSelection }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "command-container" },
                  [
                    _c("admin-device-configurator", {
                      attrs: { devices: _vm.selectedDevices }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-select-devices",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Select Devices"
          }
        },
        [
          _c("admin-device-list", {
            attrs: { "show-fields": _vm.deviceFields },
            on: { change: _vm.changeDeviceSelect }
          }),
          _c("b-button", { on: { click: _vm.clickAddDevices } }, [
            _vm._v("Add Devices")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }